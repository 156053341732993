import * as React from 'react';
import { LABEL } from '@core/constants';
import { useDisallowAuthRoutes, useRoutes } from '@core/hooks';
import BaseHead from '@components/Head';
import Link from '@components/Link';
import ModalRoutingConsumer from '@components/auth/ModalRoutingConsumer';
import Title from '@components/auth/Title';
import Header from '@components/auth/Header';
import Footer from '@components/auth/Footer';
import LogInForm from '@components/auth/LogInForm';
import { PageProps } from '@interface/common';


export default function LogInPage({ isAuthenticating }: PageProps<any>) {
  const disallowAuthRoutes = useDisallowAuthRoutes(isAuthenticating);
  const { route, FORGOT_PASSWORD, SIGNUP } = useRoutes();

  return (
    <ModalRoutingConsumer
      isLoading={disallowAuthRoutes}
      render={({ modal, closeTo}) => (
        <>
          <Title>{LABEL.LOGIN}</Title>
          <Header>
            <p>
              {`Don't have an account? `}
              <Link
                to={route(SIGNUP)}
                asModal={modal}
              >
                {LABEL.SIGNUP}
              </Link>.
            </p>
          </Header>
          <LogInForm
            redirect={closeTo}
            showIdentityButtons
          />
          <Footer>
            <p>
              {`Forgot password? `}
              <Link
                to={route(FORGOT_PASSWORD)}
                asModal={modal}
              >
                Request reset
              </Link>.
            </p>
          </Footer>
        </>
      )}
    />
  );
}

export function Head() {
  return <BaseHead title={LABEL.LOGIN}/>;
}
