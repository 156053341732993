import * as React from 'react';
import clsx from 'clsx';
import * as styles from './Footer.module.scss';


export type Props = React.HTMLAttributes<HTMLDivElement>;

export default function Footer({ children, className, ...other }: Props) {
  return <div {...other} className={clsx('format', styles.footer, className)}>{children}</div>
}
