import * as React from 'react';
import { navigate } from 'gatsby';
import { Form, Field } from 'react-final-form';
import { toast } from 'react-toastify';
import { isUndefined } from 'lodash';
import { useUser, useCart, useRoutes, useAnalytics } from '@core/hooks';
import * as api from '@core/api';
import Validation, { focusOnErrorDecorator, handleSubmissionError, isEmail } from '@core/forms';
import Header from '@components/auth/Header';
import Separator from '@components/Separator';
import { FacebookIdentityButton, GoogleIdentityButton } from '@components/thirdparty';
import TextInput from '@components/forms/TextInput';
import PasswordInput from '@components/forms/PasswordInput';
import ButtonSubmit from '@components/forms/ButtonSubmit';
import { API } from '@interface/common';


const FORM_ID = 'login-form';

const schema = new Validation({
  email: {
    required: true,
    use: { isEmail },
  },
  password: {
    required: true,
  },
});

const focusOnError = focusOnErrorDecorator(FORM_ID);

type FormValues = API.LogIn.Form;

type Props = {
  redirect?: string | null;
  isGuest?: boolean;
  showIdentityButtons?: boolean;
  className?: string;
}

export default function LogInForm({ redirect, isGuest = false, showIdentityButtons = false, className }: Props) {
  const { signIn } = useUser();
  const { getCartData } = useCart();
  const { route, ACCOUNT } = useRoutes();
  const tracking = useAnalytics();

  const handleSignIn = async (response: API.LogIn.Response) => {
    signIn(response, isGuest);
    let path = redirect ? redirect : route(ACCOUNT);
    await navigate(path, { state: { isGuest, noScroll: !isUndefined(redirect) }, replace: isGuest });
  };

  const handleProviderSignIn = React.useCallback(handleSignIn, []);

  const handleValidateForm = React.useCallback((values: FormValues) => schema.validate(values), []);

  const handleSubmitForm = React.useCallback(async (values: FormValues): Promise<any> => {
    try {
      const response = await api.logIn(values, getCartData(isGuest));
      tracking.auth('login', 'Password');
      await handleSignIn(response);
    } catch (error) {
      toast.error(error.message);
      return handleSubmissionError(error);
    }
  }, [getCartData]);

  return (
    <>
      {showIdentityButtons && (
        <>
          <Header noMargin>
            <GoogleIdentityButton
              text="signin"
              onSuccess={handleProviderSignIn}
            />
            <FacebookIdentityButton
              onSuccess={handleProviderSignIn}
            />
          </Header>
          <Separator>or</Separator>
        </>
      )}
      <Form
        validate={handleValidateForm}
        // @ts-ignore
        decorators={[focusOnError]}
        onSubmit={handleSubmitForm}
        render={({ handleSubmit }) => (
          <form
            id={FORM_ID}
            className={className}
            onSubmit={handleSubmit}
            noValidate
          >
            <Field
              name="email"
              type="email"
              label="Email"
              autoComplete="on"
              component={TextInput}
              required
            />
            <Field
              name="password"
              type="password"
              label="Password"
              autoComplete="on"
              component={PasswordInput}
              useToggle
              required
            />
            <ButtonSubmit
              label="Sign in"
              stretch
            />
          </form>
        )}
      />
    </>
  );
}
