import * as React from 'react';
import { ModalRoutingContext, ModalRoutingContextArgs } from '@plugins/gatsby-plugin-modal-routing';
import Spinner from '@components/Spinner';
import Wrapper from '@components/Wrapper';


type Props = {
  render: ({ modal, closeTo }: ModalRoutingContextArgs) => React.ReactNode;
  isLoading?: boolean;
};

export default function ModalRoutingConsumer({ isLoading = false, render }: Props) {
  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }: ModalRoutingContextArgs) => (
        modal ?
          <>
            {render({ modal, closeTo })}
            {isLoading && <Spinner overlay/>}
          </> :
          <Wrapper useFixedWidth useFullHeight>
            {isLoading ?
              <Spinner overlay/> :
              render({ modal, closeTo })
            }
          </Wrapper>
      )}
    </ModalRoutingContext.Consumer>
  );
}
