import * as React from 'react';
import clsx from 'clsx';
import * as styles from './Header.module.scss';


export type Props = React.HTMLAttributes<HTMLDivElement> & {
  noMargin?: boolean;
};

export default function Header({ children, className, noMargin = false, ...other }: Props) {
  return (
    <div
      {...other}
      className={clsx('format', styles.header, className, { [styles.margin]: !noMargin })}
    >
      {children}
    </div>
  );
}
