import * as React from 'react';
import * as styles from './Separator.module.scss';


type Props = {
  children: string;
  className?: string;
};

export default function Separator({ children }: Props) {
  return <div className={styles.separator}>{children}</div>;
}
